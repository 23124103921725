@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        transform: translate3d(0, -3000px, 0);
        opacity: 0;
    }

    60% {
        transform: translate3d(0, 25px, 0);
        opacity: 1;
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        transform: translate3d(-50px, 0, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
