.homepage-hero {
    overflow: hidden;
}

.homepage-hero__content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.homepage-hero__title,
.homepage-hero__values {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
    font-size: $font-size-h3;
}

.homepage-hero__title {
    animation-name: bounceInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: 0.5s;
    text-align: center;
}

.homepage-hero__values {
    font-size: $font-size-h5;

    span {
        display: inline-block;
        animation-name: fadeInLeft;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        animation-delay: 1s;

        &:nth-child(2) {
            animation-delay: 1.5s;
        }

        &:nth-child(3) {
            animation-delay: 2s;
        }
    }
}

.homepage-hero__animation {
    width: 100%;
    height: auto;
}

@include mq($from: md) {
    .homepage-hero__content {
        --max-height: 450px;
        flex-direction: row;
        justify-content: space-around;
        height: min(calc(85vh - 54px), var(--max-height));
        margin-bottom: 0;
    }

    .homepage-hero__title {
        font-size: $font-size-h2;
        text-align: left;
    }

    .homepage-hero__values {
        font-size: $font-size-h4;

        span {
            display: block;
        }
    }

    .homepage-hero__animation {
        flex-basis: 800px;
        align-self: flex-start;
        width: auto;
        height: 100%;
    }
}

@include mq($from: lg) {
    .homepage-hero__content {
        --max-height: 600px;
    }
}

@include mq($from: xl) {
    .homepage-hero__content {
        --max-height: 700px;
    }
}
