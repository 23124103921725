.footer {
    @include angled-edge('outside top','upper left', $brand-secondary, 50);
    background-color: $brand-secondary;
    color: #fff;
}

.footer__address {
    margin-bottom: 0;
}

.footer__pages {
    margin: 1rem 0 0 0;

    a {
        display: block;
    }
}

.footer__socials {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;

    a {
        display: inline-flex;
        margin-right: 2rem;
        color: #fff;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}
