// component styling

.carousel__item {
    width: 100%;
}

.carousel__visual {
    display: flex;
    width: 200px;
    height: 200px;
    margin: 1rem auto 0 auto;

    @include mq($from: md, $until: lg) {
        margin: 0;
    }

    @include mq($from: lg) {
        width: 300px;
        height: 300px;
        margin: 0 auto;

        &.carousel__visual--lg {
            width: 350px;
            height: 350px;
        }
    }
}

.flickity-button__icon {
    width: 100%;
    height: auto;
}

// flickity overrides
.flickity-viewport {
    transition: height 0.2s;
}

.flickity-button {
    background: transparent;
}

.flickity-button--filled {
    .arrow-path {
        fill: $brand-primary;
    }

    &:hover {
        .arrow-path {
            fill: #fff;
        }
    }
}

.flickity-prev-next-button {
    z-index: $zindex-carousel-button;
    display: none;
    width: 26px;
    height: 30px;
    padding: 0;
    border-radius: 0;

    &:focus,
    &:hover {
        box-shadow: none;
        background: transparent;
    }

    &:hover {
        .inner-button {
            fill: #fff;
        }
    }

    &.prev {
        left: -10px;
    }

    &.next {
        right: -10px;
    }
}

.flickity-page-dots {
    position: static;
}

@include mq($from: sm) {
    .carousel--hide-dots-on-desktop .flickity-page-dots {
        display: none;
    }

    .flickity-prev-next-button {
        display: block;
    }
}
