$base-font-family: 'Avenir', sans-serif;
$heading-font-family: 'Avenir', sans-serif;
$heading-font-weight: 700;

$link-decoration: none !default;

$font-size-h1: 2.75rem !default;
$font-size-h2: 1.75rem !default;
$font-size-h3: 1.5rem !default;
$font-size-h4: 1.25rem !default;
$font-size-h5: 1rem !default;
$font-size-h6: 1rem !default;

$font-size-small: 0.8rem !default;
