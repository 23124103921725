.project-item {
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
}

.project-item__image {
    width: 100%;
}

.project-item__title {
    width: 100%;
    padding: 10px;
    margin: 0;
    font-size: 1.25rem;
    transition: padding 0.2s ease-out;

    .project-item__link:hover &,
    .project-item__link:focus & {
        padding-bottom: 40px;
    }
}

.project-item__title--primary {
    @include angled-edge('outside top','upper right', #fff, 20);
    position: absolute;
    z-index: 1;
    bottom: 0;
    background-color: #fff;
    color: $brand-secondary;

    .project-item__link:hover &,
    .project-item__link:focus & {
        color: $brand-primary;
    }
}

.project-item__title--secondary {
    @include angled-edge('outside top','upper right', $brand-tertiary, 20);
    position: absolute;
    z-index: $zindex-project-item-title;
    bottom: 0;
    background-color: $brand-tertiary;
    color: #fff;

    .project-item__link:hover &,
    .project-item__link:focus & {
        color: #fff;
    }
}

.project-item__button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    transform: translateY(20px);
    transition: all 0.2s ease-out;
    opacity: 0;

    .project-item__title--secondary + & {
        color: #fff;
    }

    .project-item__link:hover &,
    .project-item__link:focus & {
        transform: translateY(0);
        transition-delay: 0.1s;
        opacity: 1;
    }
}

@include mq($from: lg) {
    .project-item {
        margin-bottom: 2rem;
    }

    .project-item__title {
        font-size: 1.5rem;
    }
}
