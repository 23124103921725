@use "sass:math";

.block:not(:last-child) {
    margin-bottom: 2rem;
}

@include mq($until: md) {
    .block__animation,
    .block__image {
        display: inherit;
        max-width: 280px;
        margin: 0 auto;
    }
}

@include mq($from: lg) {
    .block--image,
    .block--embed {
        margin-left: -#{percentage(math.div(1,12))};
        margin-right: -#{percentage(math.div(1,12))};
    }
}
