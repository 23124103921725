.page-404 {
    padding-top: 0 !important;
}

.page-404__animation {
    max-width: 400px;
    margin: 0 auto;
}

.page-404__content {
    text-align: center;
}
