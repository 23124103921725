.article-overview__item {
    padding: 1rem 0;
    border-bottom: 1px solid $line-color;

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
}

.article-overview__item-title {
    font-size: $font-size-h3;
    font-weight: 400;
}
