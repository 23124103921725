.section {
    position: relative;
    padding: 2rem 0;

    // correct spacing because of footer angled edge
    .content > &:last-child {
        padding-bottom: calc(2rem + 50px);
    }
}

// color modifiers
.section--primary {
    background-color: $brand-tertiary;
    color: #fff;
}

.section--secondary {
    background-color: $brand-secondary;
    color: #fff;
}

// angles
.section--angle-top-left {
    @include angled-edge('inside top','lower left', #fff, 50);
    padding-top: calc(2rem + 50px);
}

.section--angle-top-right {
    @include angled-edge('inside top','lower right', #fff, 50);
    padding-top: calc(2rem + 50px);
}

.section--angle-bottom-left {
    @include angled-edge('inside bottom','upper left', #fff, 50);
    padding-bottom: calc(2rem + 50px);
}

.section--angle-bottom-right {
    @include angled-edge('inside bottom','upper right', #fff, 50);
    padding-bottom: calc(2rem + 50px);
}

.section__action {
    margin-top: 1rem;
    text-align: center;
}

// only use on white sections that have no angles
.section__title {
    @include angled-edge('inside top','lower left', #fff, 50);
    @include angled-edge('inside bottom','upper right', #fff, 50);
    padding: calc(2rem + 50px) 0;
    margin: 0;
    background-color: $brand-tertiary;
    text-align: center;
    color: #fff;
}

.section-flex {
    display: flex;
    flex-direction: column;
    min-height: 95vh;

    .section { // stylelint-disable-line no-descending-specificity
        display: flex;
        flex: 1 1 auto;
        align-items: center;
    }

    .section__title {
        flex: none;
    }
}

.section--full-height {
    min-height: 95vh;
}

@include mq($from: md) {
    .section {
        p:last-child {
            margin-bottom: 0;
        }
    }
}

@include mq($from: xl) {
    .section {
        padding: 4rem 0;

        // correct spacing because of footer angled edge
        .content > &:last-child {
            padding-bottom: calc(4rem + 50px);
        }
    }

    .section--angle-top-left,
    .section--angle-top-right {
        padding-top: calc(4rem + 50px);
    }

    .section--angle-bottom-left,
    .section--angle-bottom-right {
        padding-bottom: calc(4rem + 50px);
    }
}
