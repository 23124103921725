$tooltip-arrow-width: 25px;
$tooltip-arrow-height: 30px;
$tooltip-arrow-border-color: $brand-secondary;
$tooltip-arrow-color: #fff;
$tooltip-max-width: 320px;
$tooltip-padding-y: 1rem;
$tooltip-padding-x: 1rem;
$tooltip-border-radius: 20px;
$tooltip-bg: #fff;
$tooltip-color: $body-color;
$tooltip-border-color: $brand-secondary;

.tooltip {
    position: absolute;
    z-index: $zindex-tooltip;
    top: 0;
    left: 50%;
    width: $tooltip-max-width;
    max-width: 90vw;
    transform: translate(-50%, -100%) scale(0);
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    pointer-events: none;
    word-wrap: break-word;

    &.is-active {
        transform: translate(-50%, -100%) scale(1);
    }
}

.tooltip-arrow {
    position: absolute;
    right: 20%;
    bottom: -($tooltip-arrow-height);
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: $tooltip-arrow-height $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-border-color;
        border-bottom: 0;
        border-left: 0;
    }

    &:after {
        content: '';
        position: absolute;
        top: -2px;
        width: 0;
        height: 0;
        margin-left: 2px;
        border-color: transparent;
        border-style: solid;
        border-width: 28px 22px 0;
        border-top-color: $tooltip-arrow-color;
        border-bottom: 0;
        border-left: 0;
    }
}

.tooltip-inner {
    padding: $tooltip-padding-y $tooltip-padding-x;
    border: 2px solid $tooltip-border-color;
    border-radius: $tooltip-border-radius;
    background-color: $tooltip-bg;
    color: $tooltip-color;
    text-align: center;
}
