$header-height: 54px;
$project-detail-title-height: 110px;

.spotlight {
    position: relative;
    width: 100%;
    height: 56.25vw; // use 16:9 aspect ratio
    min-height: 320px;
    max-height: calc(100vh - #{$header-height} - #{$project-detail-title-height}); // but never larger than the screen with project title
    overflow: hidden;
}

.spotlight--text {
    @include angled-edge('inside bottom','upper right', #fff, 50);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand-tertiary;
}

.spotlight__title {
    max-width: 550px;
    margin: 0 20px;
    color: #fff;
    text-align: center;
    animation-name: fadeInLeft;
    animation-duration: 0.5s;
    animation-fill-mode: both;

    @include mq($from: md) {
        font-size: 4.5rem;
    }
}

.spotlight__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);
    color: $brand-secondary;

    .is-loading & {
        display: block;
    }
}

.spotlight__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.spotlight__image {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.spotlight__audio-control {
    position: absolute;
    z-index: $zindex-spotlight-controls;
    bottom: 20px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 0;
    border-radius: 50%;
    background-color: rgba(#fff, 0.5);
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

.spotlight__audio-control-volume-up {
    display: inline-block;

    .is-muted & {
        display: none;
    }
}

.spotlight__audio-control-volume-mute {
    display: none;

    .is-muted & {
        display: inline-block;
    }
}

// stylelint-disable no-descending-specificity
.spotlight__audio-control-volume-up,
.spotlight__audio-control-volume-mute {
    width: 24px;
    height: 24px;
    transform: translateZ(0);
    transform-origin: 50% 50%;

    path {
        fill: $brand-secondary;
    }

    .spotlight__audio-control:hover & {
        animation-name: pulse;
        animation-duration: 0.6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}
// stylelint-enable no-descending-specificity

@include mq($from: lg) {
    .spotlight__audio-control {
        bottom: 50px;
        right: 50px;
        width: 48px;
        height: 48px;
    }

    .spotlight__audio-control-volume-up,
    .spotlight__audio-control-volume-mute {
        width: 32px;
        height: 32px;
    }
}

@keyframes pulse {
    25% {
        transform: scale(1.1, 1.1);
    }

    75% {
        transform: scale(0.9, 0.9);
    }
}
