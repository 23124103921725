.link--readmore {
    .icon {
        vertical-align: middle;
        margin-left: 0.5rem;
        animation: pulse-arrow 2s infinite;
    }
}

.link--light {
    color: #fff;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: underline;
    }
}

.link--secondary {
    color: $brand-secondary;

    &:hover,
    &:focus {
        color: $brand-primary;
    }
}

.link--emphasize {
    text-decoration: underline;
    font-weight: bold;
}

@keyframes pulse-arrow {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(5px);
    }

    40% {
        transform: translateX(-5px);
    }

    60% {
        transform: translateX(0);
    }
}
