$header-nav-trigger-color: $body-color !default;
$header-nav-trigger-color-active: #fff !default;

.header-nav-trigger {
    position: relative;
    z-index: $zindex-header-nav-overlay;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 2.5rem;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    background: none;
    appearance: none;
    transition: background 0.3s;

    &:focus {
        outline: none;
    }

    .mobile-nav-is-active & {
        &:before {
            content: '';
            position: fixed;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: $brand-tertiary;
        }
    }
}

.header-nav-trigger__hamburger {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 2px;
    background-color: $header-nav-trigger-color;
    transition: background 0.15s;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $header-nav-trigger-color;
        transition-duration: 0.3s, 0.3s;
    }

    &:before {
        transform: translateY(-10px);
        transition-property: transform;
    }

    &:after {
        transform: translateY(10px);
        transition-property: transform;
    }

    .mobile-nav-is-active & {
        background-color: transparent;

        &:before {
            transform: rotate(45deg) translateY(0);
        }

        &:after {
            transform: rotate(-45deg) translateY(0);
        }

        &:before,
        &:after {
            background-color: $header-nav-trigger-color-active !important;
            transition-delay: 0.3s, 0s;
        }
    }
}

.header-nav-trigger__text {
    margin-right: 0.5rem;
    text-transform: uppercase;
    color: $body-color;
    font-size: 0;
    line-height: 24px; // text is not visible but we need this for click pointer

    .mobile-nav-is-active & {
        color: $header-nav-trigger-color-active;
    }
}

@include mq($from: lg) {
    .header-nav-trigger {
        display: none;
    }
}
