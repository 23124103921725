.employee {
    position: relative;
    margin-bottom: 2rem;
    text-align: center;
}

.employee__name {
    margin: 0;
    font-size: $font-size-h4;
}

.employee__animation {
    max-width: 350px;
    margin: 0 auto;
}
