$header-nav-color-mobile: #fff;
$header-nav-color: #707070;
$header-nav-color-hover: $brand-primary;

.header {
    position: relative;
    z-index: $zindex-header;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .mobile-nav-is-active & {
        z-index: $zindex-header-nav-overlay;
    }
}

.header__container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.header__logo {
    vertical-align: middle;
    width: 300px;
    max-width: 100%;
    height: 16px;
    fill: #000;
}

.header__nav {
    display: none;

    .mobile-nav-is-active & {
        position: fixed;
        z-index: $zindex-header-nav-overlay-content;
        top: 50%;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%);
    }
}

.header__nav-item {
    display: block;
    margin: 1em 0;
    color: $header-nav-color-mobile;
    font-size: 1.5rem;

    .icon {
        width: 0.75em;
        height: 0.75em;
        margin-right: 0.5rem;
        transform: translateX(0);
        transition: transform 0.2s ease-out;

        path {
            stroke: $header-nav-color-mobile;
        }
    }

    &:hover,
    &:focus {
        .icon {
            transform: translateX(-5px);
        }
    }

    .mobile-nav-is-active & {
        animation-name: fadeInLeft;
        animation-duration: 0.2s;
        animation-fill-mode: both;
    }
}

@for $i from 1 through 4 {
    .mobile-nav-is-active .header__nav-item:nth-child(#{$i}) {
        animation-delay: #{$i * 0.2}s;
    }
}

@include mq($from: lg) {
    .header__nav {
        display: flex;
    }

    .header__nav-item {
        margin: 0 1em;
        color: $header-nav-color;
        font-size: 1rem;

        &:first-child {
            margin-left: 0;
        }

        .icon {
            margin-right: 0;

            path {
                stroke: $brand-primary;
            }
        }

        &:hover,
        &:focus,
        &.is-active {
            color: $header-nav-color-hover;
        }
    }
}
