$font-path: '../../fonts/';

@font-face {
    font-family: 'Avenir';
    src: url($font-path + 'avenir-black-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url($font-path + 'avenir-roman-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
