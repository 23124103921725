.project-detail__header {
    @include angled-edge('outside top','upper right', $brand-tertiary, 50);
    padding-top: 1rem !important;

    @include mq($from: xl) {
        padding-bottom: calc(3rem + 50px);
    }
}

.project-detail__title {
    opacity: 0;
    animation: 0.3s fadeInLeft both;
    animation-delay: 1s;
}

.project-detail__introduction {
    font-size: $font-size-h4;
    font-weight: bold;
    opacity: 0;
    animation: 0.3s fadeInLeft both;
    animation-delay: 2s;
}

.project-detail__icon {
    position: relative;
    top: 1rem; // correct padding of angled line
    width: 100%;
    max-width: 200px;
    height: auto;

    @include mq($until: sm) {
        display: none;
    }
}
