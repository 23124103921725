blockquote {
    font-style: italic;
    text-align: center;
    font-size: $font-size-h2;

    &:before {
        content: open-quote;
    }

    &:after {
        content: close-quote;
    }

    p {
        display: inline;
    }
}

@include mq($from: xl) {
    blockquote {
        font-size: $font-size-h1;
    }
}
